import React, { useCallback, useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import BettingDialog from '../dialogs/BettingDialog';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog';
import WinDialog from '../dialogs/WinDialog';
import RuleDialog from '../dialogs/FastParityRuleDialog';
import { setStorage, getStorage } from '../modals/Storage';
import { API_ACCESS_URL } from '../modals/Constants';
import '../../MainStyle.css';

function Wingo5Min(){
    const navigate = useNavigate();
    const [remainingSec, setCount] = useState(60);
    const [disableTime, setDisable] = useState(30);

    const [pageConst, setGameData] = useState({
        pageTitle: "Wingo 5Min",
        gamePeriodId: "",
        gameCode: "WINGO5MIN",
        remainingSec1: 0,
        remainingSec2: 0,
        remainingMin1: 0,
        remainingMin2: 0,
        isControlEnable: true,
        requestAccountData: true,
        isLoadingShow: false,
        ruleDialogShow: false,
        winDialogShow: false,
        toastDialogShow: false,
        toastMessage: "",
        tabActiveReord: "everyoneorder",
        gameWinDetails: [],
        gameRecordsList: [],
        mygameRecordsList: [],
    });

    const [bettingDialogConst, setBettingDialogConst] = useState({
      bettingDialogShow: false,
      bettingDialogTitle: "Join Green",
      bettingDialogTheme: "dlg-thm-green",
      gameBetRoutePath: "route-bet-common",
      gameSelectedBet: "",
    });

    const [winDialogConst, setWinDialogConst] = useState({
      winDialogShow: false,
      constPeriodId: "",
      constWinAmount: 0,
    });

    const handleVisibilityChange = useCallback(() => {
      let isVisible = document.visibilityState === 'visible';
      if(isVisible){
        getGameData();
      }
    }, []);

    const openNewGame = (data) =>{
      if(data=="1"){
        navigate('/wingo1min', { replace: true });
      }else if(data=="2"){
        navigate('/wingo3min', { replace: true });
      }else if(data=="3"){
        navigate('/wingo5min', { replace: true });
      }else if(data=="4"){
        navigate('/wingo10min', { replace: true });
      }
    }

    const updatePeriod = (data) => {
        setGameData(previousState => {
          return { ...previousState, gamePeriodId: data[0].game_period_id }
        });
    }
    
    const updateWinDialogState = (data) => {
      setWinDialogConst(previousState => {
        return { ...previousState, winDialogShow: data }
      });
    }

    const updateGameControl = (data) => {
        setGameData(previousState => {
            return { ...previousState, isControlEnable: data }
        });
    }

    const updateReqAcntData = (data) => {
        if(data=="false"){
            setGameData(previousState => {
                return { ...previousState, requestAccountData: false }
            });
        }else{
            setGameData(previousState => {
                return { ...previousState, requestAccountData: true }
            });
        }
    }

    const updateRemainSec = (data,val) => {
        if(data=="1"){
            setGameData(previousState => {
                return { ...previousState, remainingSec1: val }
            });
        }else{
            setGameData(previousState => {
                return { ...previousState, remainingSec2: val }
            });
        }
    }

    const updateRemainMin = (data,val) => {
        if(data=="1"){
            setGameData(previousState => {
                return { ...previousState, remainingMin1: val }
            });
        }else{
            setGameData(previousState => {
                return { ...previousState, remainingMin2: val }
            });
        }
    }

    const updateActiveTab = (data) => {
        setGameData(previousState => {
            return { ...previousState, tabActiveReord: data }
        });
    }

    const updateLoadingStatus = (data) => {
      setGameData(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const getDialogThemeClassName = (data) =>{
      let returnVal = "dlg-thm-green";

      if(data=="r" || data=="rv"){
        returnVal = "dlg-thm-red";
      }else if(data=="v"){
        returnVal = "dlg-thm-violet";
      }else if(data=="b"){
        returnVal = "dlg-thm-orange";
      }else if(data=="s"){
        returnVal = "dlg-thm-blue";
      }

      return returnVal;
    }
    
    const updateBettingDialogState = (e,data,selected,title) => {

      if(selected!=""){
        setBettingDialogConst(previousState => {
          return { ...previousState, gameSelectedBet: selected }
        });
      }

      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });
      }else if(data==true && pageConst.isControlEnable){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });

        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogTitle: title }
        });

        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogTheme: getDialogThemeClassName(getColourCodeVal(selected)) }
        });
        
      }else if(data=="dismiss"){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: false }
        });
      }
    }

    const showGameRules = () =>{
      setGameData(previousState => {
        return { ...previousState, ruleDialogShow: true }
      });
    }

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){

      }else{
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    }

    const updateGameRuleDialog = (e,data) => {
      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: data }
        });
      }else if(data=="dismiss"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: false }
        });
      }else if(data=="true"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    } 

    const updateToastDialogState = (data,msg) => {

      setGameData(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setGameData(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const getCapitalLetter = (data) => {
       let returnVal = "";

       if(data=="red"){
         returnVal = "R";
       }else if(data=="green"){
         returnVal = "G";
       }else if(data=="violet"){
         returnVal = "V";
       }else if(data=="b"){
        returnVal = "B";
       }else if(data=="s"){
        returnVal = "S";
       }else{
         returnVal = data;
       }

       return returnVal;  
    }

    const getBigSmall = (data) =>{
      let returnVal = "";

      if(data >= 5){
        returnVal = "B";
      }else{
        returnVal = "S";
      }

      return returnVal;
    }

    const getColourCodeVal = (data) => {
        let returnVal = "";

        if(data=="1" || data=="3" || data=="7" || data=="9"){
            returnVal = "g";
        }else if(data=="2" || data=="4" || data=="6" || data=="8"){
            returnVal = "r";
        }else if(data=="0"){
            returnVal = "rv";
        }else if(data=="5"){
            returnVal = "gv";
        }else if(data=="red"){
          returnVal = "r";
        }else if(data=="green"){
          returnVal = "g";
        }else if(data=="violet"){
          returnVal = "v";
        }else if(data=="b"){
          returnVal = "b";
        }else if(data=="s"){
          returnVal = "s";
        }

        return returnVal;
    }

    const updateGameRecords = (data) => {
        let tempData = [];
        for (let i = 0; i < data.length; i++) {
          let matchResult = data[i]['match_result'];
  
          tempData.push(
            <div key={data[i]['period_id']} className="row-view mg-t-15 sb-view">
              <span className='w-50-p cl-white ft-sz-14'>{data[i]['period_id']}</span>
              <span className={`v-center h-w-28 ft-w-700 ft-sz-23 ${getColourCodeVal(matchResult)=="r" ? 'cl-red' : getColourCodeVal(matchResult)=="g" ? 'cl-green' : getColourCodeVal(matchResult)=="rv" ? 'cl-rv' : 'cl-gv'}`}>{matchResult}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}>{getBigSmall(matchResult)=='B' ? 'Big' : 'Small'}</span>
              <span className={`ft-sz-17 h-w-15 br-50 cl-white v-center ${getColourCodeVal(matchResult)=="r" ? 'bg-red' : getColourCodeVal(matchResult)=="g" ? 'bg-green' : getColourCodeVal(matchResult)=="rv" ? 'bg-rv' : 'bg-gv'}`}></span>
            </div>)
        }

        setGameData(previousState => {
          return { ...previousState, gameRecordsList: tempData }
        });
    }

    const getFixedDecimalVal = (data) => {
      return Number(data).toFixed();
    }

    const recentBetDetails = (invested_amount) =>{
      setStorage("recentfastparitybet", pageConst.gamePeriodId);

      let tempData = [];

      tempData.push(
        <div key={60} className="row-view mg-t-15 sb-view">
          <span className='cl-white ft-sz-14'>{pageConst.gamePeriodId}</span>
          <span className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${getColourCodeVal(bettingDialogConst.gameSelectedBet)=="r" ? 'bg-red' : getColourCodeVal(bettingDialogConst.gameSelectedBet)=="g" ? 'bg-green' : getColourCodeVal(bettingDialogConst.gameSelectedBet)=="rv" ? 'bg-rv' : getColourCodeVal(bettingDialogConst.gameSelectedBet)=="gv" ? 'bg-gv' : 'bg-violet'}`}>{getCapitalLetter(bettingDialogConst.gameSelectedBet)}</span>
          <span className={`cl-white ft-sz-17 h-w-28 br-50 v-center`}></span>
          <span className={`cl-white w-65-p ft-sz-15 txt-a-end`} >{invested_amount}.00</span>
        </div>)
        
        let finalArr = [tempData].concat(pageConst.mygameRecordsList);
        
        setGameData(previousState => {
          return { ...previousState, mygameRecordsList: finalArr }
        });
    }

    const updateMyGameRecords = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {

          if(i==0 && getStorage("recentfastparitybet")==data[i]['m_period_id']){
            console.log(data[i]);

            if(data[0]['m_status']=="profit"){
              setWinDialogConst(previousState => {
                return { ...previousState, constPeriodId: data[0]['m_period_id'] }
              });
  
              setWinDialogConst(previousState => {
                return { ...previousState, constWinAmount: data[0]['m_profit'] }
              });

              setStorage("recentfastparitybet", "");
              updateWinDialogState(true);
            }
          }

          let tempMatchReslt = "";
          if(data[i]['m_status']=="profit"){
            if(Number(data[i]['m_profit']) >= 1000000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 100000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 10000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else{
              tempMatchReslt = "+₹"+Number(data[i]['m_profit']);
            }
          }else if(data[i]['m_status']=="loss"){
            if(Number(data[i]['m_cost']) >= 1000000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 100000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 10000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else{
              tempMatchReslt = "-₹"+Number(data[i]['m_cost']);
            }
          }
          
          tempData.push(
            <div key={i} className="row-view mg-t-15 sb-view">
              <span className='cl-white ft-sz-14'>{data[i].m_period_id}</span>
              <span className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${getColourCodeVal(data[i]['m_color'])=="r" ? 'bg-red' : getColourCodeVal(data[i]['m_color'])=="g" ? 'bg-green' : getColourCodeVal(data[i]['m_color'])=="rv" ? 'bg-rv' : getColourCodeVal(data[i]['m_color'])=="gv" ? 'bg-gv' : getColourCodeVal(data[i]['m_color'])=="b" ? 'bg-drk-orange' : getColourCodeVal(data[i]['m_color'])=="s" ? 'bg-blue' : 'bg-violet'}`}>{getCapitalLetter(data[i]['m_color'])}</span>
              <span className={`cl-white ft-sz-17 h-w-28 br-50 v-center ${getColourCodeVal(data[i]['m_result'])=="r" ? 'bg-red' : getColourCodeVal(data[i]['m_result'])=="g" ? 'bg-green' : getColourCodeVal(data[i]['m_result'])=="rv" ? 'bg-rv' : getColourCodeVal(data[i]['m_result'])=="gv" ? 'bg-gv' : ''} `}>{getCapitalLetter(data[i]['m_result'])}</span>
              <span className={`w-65-p ft-sz-15 txt-a-end ${data[i]['m_status']=="loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
            </div>)
        };

        setGameData(previousState => {
            return { ...previousState, mygameRecordsList: tempData }
        });
    }

    const getRandBool = () => {
      let returnVal = false;

      let status=Math.round(Math.random())
      if(status==1){
        returnVal = true;
      }

      return returnVal;
    }

    const getRandomNum = (min, max, type) => {
      if(type=="multi"){
        return Math.round((Math.random()*(max-min)+min)/10)*10;
      }else{
        return Math.floor(Math.random() * (max - min) + min);
      }
    }

    function getGameData(){
      updateLoadingStatus(true);

      const fecthApiData = async (url) => {
        
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-setup-common',
              'AuthToken': getStorage("secret"),
            }
          });

          const data = await res.json();

          updateLoadingStatus(false);

          if(data.status_code=="success"){
            updatePeriod(data.gamedata);
            setCount(data.gamedata[0].game_remain_seconds);
            setDisable(data.gamedata[0].game_disable_time);
            updateGameRecords(data.matchrecords);
            updateMyGameRecords(data.mymatchrecords);
          }else{
            updateToastDialogState(true, "Something went wrong! Please try again or login!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          console.log(error);
        }
      };

      fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PROJECT_NAME="+pageConst.gameCode);
    }

    function setUpTimer(data){
        if(data=="true"){
            updateReqAcntData("true");
        }

        if(pageConst.requestAccountData==true){
            updateReqAcntData("false");
            getGameData();
        }
    }

    useEffect(() => {

        document.addEventListener("visibilitychange", handleVisibilityChange);
        setUpTimer("false");

        const interval = setInterval(() => {
            if(remainingSec >= 0){
                setCount(remainingSec - 1);

                if(remainingSec <= 0){
                    setUpTimer("true");
                    setCount(60);
                }else{

                    function splitIntoArray(num) {
                      return Array.from(String(num), Number);
                    }

                    const minutes = Math.floor(remainingSec / 60);
                    const seconds = remainingSec % 60;
                
                    if (minutes < 10) {
                      updateRemainMin("1", 0);
                      updateRemainMin("2", minutes);
                    } else {
                      var numArr = splitIntoArray(minutes);
                      updateRemainMin("1", numArr[0]);
                      updateRemainMin("2", numArr[1]);
                    }
                
                    if (seconds < 10) {
                      updateRemainSec("1", 0);
                      updateRemainSec("2", seconds);
                    } else {
                      var numArr = splitIntoArray(seconds);
                      updateRemainSec("1", numArr[0]);
                      updateRemainSec("2", numArr[1]);
                    }

                    if(remainingSec < disableTime && pageConst.isControlEnable == true){
                      updateGameControl(false);
                    }else if (remainingSec > disableTime && pageConst.isControlEnable == false) {
                      updateGameControl(true);
                    }
                }
            }
        }, 1000);

        //Clearing the interval
        return () => {
          clearInterval(interval);
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        }

    }, [remainingSec]);


    return (
      <div className="v-center bg-black">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction}/>
           <LoadingDialog intentData={pageConst}/>
           <WinDialog intentData={winDialogConst} updateState={updateWinDialogState}/>
           <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
           <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog}/>
           <BettingDialog gameCode={pageConst.gameCode} dialogData={bettingDialogConst} updateState={updateBettingDialogState} toastUpdate={updateToastDialogState} recentBetDetails={recentBetDetails} />

           <div className="col-view bg-black mg-t-45">

              <div className="col-view">

               <div className='pd-15'>
                <div className="pd-10 br-10 bg-gold-grad-2">
                  <p className='ft-sz-14 cl-grey'>Balance</p>
                  <p className="cl-black ft-sz-23 ft-wgt-500">₹{getStorage("balance")}</p>

                  <div className='w-100 row-view sb-view mg-t-15'>
                    <Link className="v-center txt-deco-n pd-5-35 br-5 ft-sz-18 cl-white ft-wgt-500 bg-drk-grey" to={"/recharge"}>Deposit</Link>
                    <Link className="v-center txt-deco-n pd-5-35 br-5 ft-sz-18 cl-grey ft-wgt-500 bg-transparent br-a-grey" to={"/withdraw?M=W"}>Withdraw</Link>
                  </div>
                </div>
               </div>

               <div>
                
               </div>

               <div className='pd-0-15'>
                 <div className="g-v-4 w-100 br-10 bg-grey-black">
                   <div className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-white`} onClick={()=>openNewGame('1')}>
                     <img className='h-50-p' src={require('../icons/wingo/2.png')} alt="icon" />
                     <span>Win Go</span>
                     <span>1Min</span>
                   </div>

                   <div className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-white`} onClick={()=>openNewGame('2')}>
                     <img className='h-50-p' src={require('../icons/wingo/2.png')} alt="icon" />
                     <span>Win Go</span>
                     <span>3Min</span>
                   </div>

                   <div className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 bg-gold-grad-4`} onClick={()=>openNewGame('3')}>
                     <img className='h-50-p' src={require('../icons/wingo/2.png')} alt="icon" />
                     <span>Win Go</span>
                     <span>5Min</span>
                   </div>

                   <div className={`col-view v-center pd-5-10 ft-sz-14 w-100 br-10 cl-l-white`} onClick={()=>openNewGame('4')}>
                     <img className='h-50-p' src={require('../icons/wingo/2.png')} alt="icon" />
                     <span>Win Go</span>
                     <span>10Min</span>
                   </div>
                 </div>
               </div>

               <div className='pd-15'>
                 <div className="row-view sb-view bg-gtp">
                  <div className='col-view pd-10'>
                    <div className="v-center txt-deco-n pd-5-25 br-5 ft-sz-13 cl-grey ft-wgt-500 bg-transparent br-a-grey" onClick={() => showGameRules()}>
                      How to play
                    </div>
                    <p className='ft-sz-13 mg-t-5'>Win Go 1Min</p>
                    <div className='g-v-5 mg-t-10'>
                      <img className='h-w-28' src={require('../icons/wingo/n0_icon.png')} alt="icon" />
                      <img className='h-w-28' src={require('../icons/wingo/n5_icon.png')} alt="icon" />
                      <img className='h-w-28' src={require('../icons/wingo/n7_icon.png')} alt="icon" />
                      <img className='h-w-28' src={require('../icons/wingo/n9_icon.png')} alt="icon" />
                      <img className='h-w-28' src={require('../icons/wingo/n3_icon.png')} alt="icon" />
                    </div>
                  </div>
                  
                  <div className="col-view pd-10">
                    
                    <div className="col-view a-right">
                      <span className="ft-sz-14">Time Remaining</span>
                      <div className="t-countview mg-t-5">
                        <span className='bg-gold-grad-3'>{pageConst.remainingMin1}</span>
                        <span className='bg-gold-grad-3'>{pageConst.remainingMin2}</span>
                        :
                        <span className='bg-gold-grad-3'>{pageConst.remainingSec1}</span>
                       <span className='bg-gold-grad-3'>{pageConst.remainingSec2}</span>
                      </div>

                      <span className="ft-sz-18 cl-drk-grey ltr-s-2p2 ft-wgt-600 mg-t-10">{pageConst.gamePeriodId}</span>
                    </div>
                    
                  </div>
                </div>
               </div>

               <div className={`row-view pd-15  ${pageConst.isControlEnable ? '' : 'disable-view'}`}>

                <div className="col-view w-100 a-center pd-10 cl-white br-5 bg-green" onClick={()=>updateBettingDialogState(null,true,'green','Join Green')}>
                  Green
                </div>

                <div className="col-view w-100 a-center mg-l-10 pd-10 cl-white br-5 bg-violet" onClick={()=>updateBettingDialogState(null,true,'violet','Join Violet')}>
                  Violet
                </div>

                <div className="col-view w-100 a-center mg-l-10 pd-10 cl-white br-5 bg-red" onClick={()=>updateBettingDialogState(null,true,'red','Join Red')}>
                  Red
                </div>
                
               </div>

               <div className={`g-v-5 ft-sz-20 pd-20 ${pageConst.isControlEnable ? '' : 'disable-view'}`}>
                <img className='h-60-p' src={require('../icons/wingo/n0_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'0','Select 0')} />
                <img className='h-60-p' src={require('../icons/wingo/n1_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'1','Select 1')} />
                <img className='h-60-p' src={require('../icons/wingo/n2_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'2','Select 2')} />
                <img className='h-60-p' src={require('../icons/wingo/n3_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'3','Select 3')} />
                <img className='h-60-p' src={require('../icons/wingo/n4_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'4','Select 4')} />
                <img className='h-60-p' src={require('../icons/wingo/n5_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'5','Select 5')} />
                <img className='h-60-p' src={require('../icons/wingo/n6_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'6','Select 6')} />
                <img className='h-60-p' src={require('../icons/wingo/n7_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'7','Select 7')} />
                <img className='h-60-p' src={require('../icons/wingo/n8_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'8','Select 8')} />
                <img className='h-60-p' src={require('../icons/wingo/n9_icon.png')} alt="icon" onClick={()=>updateBettingDialogState(null,true,'9','Select 9')} />
               </div>

               <div className={`w-100 row-view v-center ${pageConst.isControlEnable ? '' : 'disable-view'}`}>
                <div className="col-view w-45 a-center pd-10 cl-white br-left-t-b-30 bg-drk-orange" onClick={()=>updateBettingDialogState(null,true,'b','Join Big')}>
                  Big
                </div>

                <div className="col-view w-45 a-center pd-10 cl-white br-right-t-b-30 bg-blue" onClick={()=>updateBettingDialogState(null,true,'s','Join Small')}>
                  Small
                </div>
               </div>

              </div>

              <div className='pd-0-15 mg-t-30'>

              <div className="tab-slct-v w-100">
                <div className={`v-center pd-10 br-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="everyoneorder" ? 'bg-gold-grad' : 'cl-white bg-l-white-2'}`} onClick={()=>updateActiveTab('everyoneorder')}>Game History</div>
                <div className={`v-center pd-10 br-10 ft-sz-18 w-100 mg-l-10 ${pageConst.tabActiveReord=="myorder" ? 'bg-gold-grad' : 'cl-white bg-l-white-2'}`} onClick={()=>updateActiveTab('myorder')}>My History</div>
              </div>

              <div className="col-view br-10 mg-t-25 mg-b-15 bg-grey-black">

                <div className={`col-view min-h ${pageConst.tabActiveReord!="everyoneorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view br-right-t br-left-t pd-10-15 cl-white" style={{background: '#6F6F6F'}}>
                    <span>Period</span>
                    <span>Number</span>
                    <span>Big Small</span>
                    <span>Color</span>
                  </div>

                   <div className="col-view pd-10-15">
                    {pageConst.gameRecordsList}
                   </div>
                </div>

                <div className={`col-view min-h ${pageConst.tabActiveReord!="myorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view br-right-t br-left-t pd-10-15 cl-white" style={{background: '#6F6F6F'}}>
                    <span>Period</span>
                    <span>Select</span>
                    <span>Result</span>
                    <span>Amount</span>
                  </div>

                   <div className="col-view pd-10-15">
                    {pageConst.mygameRecordsList}
                   </div>

                   <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/myorders"}>More</Link>
                </div>

              </div>

              </div>

           </div>
        </div>
      </div>
    );
}

export default Wingo5Min;